<template>
  <div>
    <b-alert variant="danger" :show="productData === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No product found with this product id. Check
        <b-link class="alert-link" :to="{ name: 'products-list' }">
          Product List
        </b-link>
        for other products.
      </div>
    </b-alert>
    <div v-if="productData && Object.keys(productData).length > 0">
      <!-- <b-card no-body class="p-1 px-2"> -->
        <!-- Product Info: Top col -->
        <!-- <div
          class="d-flex flex-wrap align-items-center justify-content-between"
        > -->
          <!-- <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
            Product - {{ productData.name }} -->
            <!-- product variant and images remaining with styling -->
            <!-- ({{ productData.brands?.name }}) -->
          <!-- </h4> -->
          <!-- <div class="d-flex detail-top-b-list flex-wrap justify-content-end"> -->
            <!-- v-if="ability.can('update', 'product_account')" -->
            <!-- <b-button
              class="px-3"
              :to="{ name: 'product-edit', params: { id: productData.id } }"
              variant="primary"
              ><feather-icon
                class="mr-1"
                size="14"
                icon="Edit2Icon"
              />Edit</b-button
            >
            <b-button
              class="px-3"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Close
            </b-button>
            <b-button
              class="px-3"
              variant="outline-danger"
              v-if="ability.can('delete', 'product_account')"
              @click="deleteProduct(productData.id)"
              ><feather-icon
                class="mr-1"
                size="14"
                icon="Trash2Icon"
              />Delete</b-button
            >
          </div>
        </div> -->

        <!-- Product Table: Left col -->
        <!-- <b-row class="info-wrap px-1">
          <b-col title="name(en)" cols="12" sm="12" md="12">
            <h4 class="mb-0 font-weight-bolder">
              {{ productData.name }} ${{ productData.price }}
            </h4>
          </b-col> -->

          <!-- <b-col title="email" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="MailIcon" />
            {{ productData.email }}
          </b-col>

          <b-col title="is active?" cols="12" sm="6" md="4">
            <feather-icon class="mr-1" size="14" icon="UserCheckIcon" />
            <template>
              <feather-icon
                v-if="productData.is_active"
                size="14"
                icon="CheckIcon"
              />
              <feather-icon v-else size="14" icon="XIcon" />
            </template>
          </b-col> -->
          <!-- <hr class="w-100 d-md-none" />
          <b-col title="name(cn)" cols="12" sm="12" md="12">
            <h6
              v-for="(categories, index) in productData.product_categories"
              :key="`${categories.id}_prod_cat_${index}`"
              class="mb-0 font-weight-bolder"
            >
              <b-badge href="#" class="mr-1 d-block" variant="primary">
                {{ categories.name }}
              </b-badge>
            </h6>
          </b-col>

          <b-col title="is active?" cols="12" sm="12" md="12">
            Product Status: &nbsp;
            <template>
              <b-badge
                v-if="productData.is_active"
                href="#"
                class="d-block"
                variant="primary"
              >
                <feather-icon size="14" icon="CheckIcon" />
              </b-badge>
              <b-badge v-else href="#" class="d-block">
                <feather-icon size="14" icon="XIcon" />
              </b-badge>
            </template>
          </b-col>

          <b-col title="is dangerous?" cols="12" sm="12" md="12">
            Dangerous Goods: &nbsp;
            <template>
              <b-badge
                v-if="productData.is_dangerous"
                href="#"
                class="d-block"
                variant="primary"
              >
                <feather-icon size="14" icon="CheckIcon" />
              </b-badge>
              <b-badge v-else href="#" class="d-block">
                <feather-icon size="14" icon="XIcon" />
              </b-badge>
            </template>
          </b-col>

          <b-col title="has warranty?" cols="12" sm="6" md="4">
            Warranty Included: &nbsp;
            <template>
              <b-badge
                v-if="productData.has_warranty"
                href="#"
                class="d-block"
                variant="primary"
              >
                <feather-icon size="14" icon="CheckIcon" />
              </b-badge>
              <b-badge v-else href="#" class="d-block">
                <feather-icon size="14" icon="XIcon" />
              </b-badge>
            </template>
          </b-col>

          <b-col
            v-if="productData.has_warranty"
            title=""
            cols="12"
            sm="6"
            md="8"
          >
            <feather-icon class="mr-1" size="14" icon="CreditCardIcon" />
            {{ productData.warranty_type.name }} -
            {{ productData.warranty_policy }} (
            {{ productData.warranty_period }}
            )
          </b-col>

          <b-col
            v-if="productData.has_warranty"
            title=""
            cols="12"
            sm="6"
            md="4"
          >
          </b-col>

          <b-col title="has warranty?" cols="12" sm="6" md="12">
            What's in the box?: &nbsp;
            <h6 class="mb-0">
              {{ productData.box_detail }}
            </h6>
          </b-col>

          <b-col cols="12" md="12">
            <b-form-group
              :label="`Package Weight (${productData.units.name}):`"
              label-for="weight"
              label-cols-sm="6"
              label-cols="12"
            >
              <b-form-input id="weight" v-model="productData.weight" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-col title="Width" cols="12" sm="4" md="3">
              <b-form-group
                label="Width"
                label-for="width"
                label-cols-sm="4"
                label-cols="12"
              >
                <b-form-input id="width" v-model="productData.width" readonly />
              </b-form-group>
            </b-col>
            <b-col title="Height" cols="12" sm="4" md="3">
              <b-form-group
                label="Height"
                label-for="height"
                label-cols-sm="4"
                label-cols="12"
              >
                <b-form-input
                  id="height"
                  v-model="productData.height"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col title="Length" cols="12" sm="4" md="3">
              <b-form-group
                label="Length"
                label-for="length"
                label-cols-sm="4"
                label-cols="12"
              >
                <b-form-input
                  id="length"
                  v-model="productData.length"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-col> -->
        <!-- </b-row>
      </b-card> -->

      <!-- Product Detail -->
      <b-row class="info-wrap">
        <!-- Product right card section  -->
        <b-col md="5" lg="4">
          <b-card class="px-1">
            <div v-if="productData.cover_image_path">
              <el-image
                :src="uploadUrl + productData.cover_image_path"
                fit="cover"
                :alt="productData.alt"
                :preview-src-list="[uploadUrl + productData.cover_image_path]"
                class="product-img"
              />
            </div>
            <b-col>
              <h4 style="text-align: center">
                {{ productData.name }}
              </h4>
            </b-col>
            <b-col>
              <h6 style="opacity: 0.8; text-align: center">
                <span style="font-weight: 600">Prouct Id:</span>
                {{ productData.id }}
              </h6>
            </b-col>

            <hr />
            <b-row>
              <p>
                <span style="font-weight: 600">Price:</span>
                Rs. {{ productData.price }}
              </p>
            </b-row>
            <b-row>
              <p>
                <span style="font-weight: 600">Brand:</span>
                <!-- {{ productData.brands.name }} -->
                N/A
              </p>
            </b-row>
            <b-row>
              <p>
                <span style="font-weight: 600; margin-right: 5px;">Category:</span>
                <p
                  v-for="(categories, index) in productData.product_categories"
                  :key="`${categories.id}_prod_cat_${index}`"
                  class="mb-0"
                >
                  <b-badge href="#" class="d-block" variant="primary" style="margin-right: 5px;">
                    {{ categories.name }}
                  </b-badge>
                </p>
              </p>
            </b-row>
            <b-row>
              <p>
                <span style="font-weight: 600; margin-right: 5px;">Dangerous Goods:</span>
               
              <b-badge
                v-if="productData.is_dangerous"
                href="#"
                
                variant="primary"
              >
                <feather-icon size="14" icon="CheckIcon" />
              </b-badge>
              <b-badge v-else href="#">
                <feather-icon size="14" icon="XIcon" />
              </b-badge>
            
              </p>
            </b-row>
            <b-row v-if="productData.has_warranty">
              <p>
                <span style="font-weight: 600">Warranty Type:</span>
                {{ productData.warranty_type.name }}
              </p>
            </b-row>
            <b-row v-if="productData.has_warranty">
              <p>
                <span style="font-weight: 600">Warranty Period:</span>
                {{ productData.warranty_period }}
              </p>
            </b-row>
            <b-row v-if="productData.has_warranty">
              <p>
                <span style="font-weight: 600">Warranty Policy:</span>
                {{ productData.warranty_policy }}
              </p>
            </b-row>
            <b-row>
              <p>
                <span style="font-weight: 600">What's in the box?:</span>
                {{ productData.box_detail }}
              </p>
            </b-row>
            <hr />
            <b-row>
              <b-col md="6" style="padding-right: 0px; padding-left: 0px;">
                <p>
                  <span style="font-weight: 600">Weight:</span>
                  {{ productData.weight }} {{ productData.units.name }}
                </p>
              </b-col>
              <b-col md="6" style="padding-right: 0px; padding-left: 0px;">
                <p>
                  <span style="font-weight: 600">Length:</span>
                  {{ productData.length }} cm
                </p>
              </b-col>
              <b-col md="6" style="padding-right: 0px; padding-left: 0px;">
                <p>
                <span style="font-weight: 600">Width:</span>
                {{ productData.width }} cm
              </p>
              </b-col>
              <b-col md="6" style="padding-right: 0px; padding-left: 0px;">
                <p>
                <span style="font-weight: 600">Height:</span>
                {{ productData.height }} cm
              </p>
              </b-col>
              <b-col style="padding-right: 0px; padding-left: 0px;">
                <div class="d-flex flex-wrap justify-content-center align-items-center mt-1" style="gap: 10px;">
                  <b-button
                    :to="{ name: 'product-edit', params: { id: productData.id } }"
                    variant="primary"
                    style="width: 100px"
                    ><feather-icon
                      size="14"
                      style="margin-right: 10px;"
                      icon="Edit2Icon"

                    />Edit</b-button
                  >
                  <b-button
                    type="button"
                    variant="outline-secondary"
                    style="width: 100px"
                    @click="hide"
                  >
                    Close
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <!-- Product left card section  -->
        <b-col md="7" lg="8">
          <b-row class="d-flex pl-1" style="gap: 5px;">
            <b-button size="sm" :variant="activeCard === 'variants' ? 'secondary' : 'light'" @click="updateActiveCard('variants')">Variants</b-button>
            <b-button size="sm" :variant="activeCard === 'highlight' ? 'secondary' : 'light'" @click="updateActiveCard('highlight')">Highlight</b-button>
            <b-button size="sm" :variant="activeCard === 'description' ? 'secondary' : 'light'" @click="updateActiveCard('description')">Description</b-button>
            <b-button size="sm" :variant="activeCard === 'shipping' ? 'secondary' : 'light'" @click="updateActiveCard('shipping')">Shipping</b-button>
            <b-button size="sm" :variant="activeCard === 'images' ? 'secondary' : 'light'" @click="updateActiveCard('images')">Images</b-button>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <b-card style="padding-right: 0px; padding-left: 0px; min-height: 350px; max-height: 720px; overflow-y: auto; margin:">

                <!-- Product Variants section -->
                <div v-if="activeCard === 'variants'">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Variants</h4>
                  </div>
                  <hr />
                  <b-row fluid v-for="(item, index) in productData.product_variant" :key="index">
                    <b-col sm="12">
                      <b-badge class="pl-1 pr-1 mb-1" style="font-weight: 400;">Variant {{ index + 1 }}</b-badge>
                    </b-col>
                    <b-col sm="6" lg="4">
                      <p>
                        <span style="font-weight: 600; margin-right: 5px;">Product Color:</span>
                          <b-badge v-if="item.color && item.color.color"
                          v-bind:style="{
                            'background-color': item.color.color,
                          }"
                        >
                          {{  item.color.color }}
                        </b-badge>
                        <b-badge v-else>
                          <!-- {{  item.color.color }} -->
                          N/A
                        </b-badge>
                      </p>
                    </b-col>
                    <b-col sm="6" lg="4">
                      <p>
                        <span style="font-weight: 600">Product Size:</span>
                        <span v-if="item.size && item.size.size">
                        {{ item.size.size }}
                        </span>
                        <span v-else>
                        N/A
                        </span>
                      </p>
                    </b-col>
                    <b-col sm="6" lg="4">
                      <p>
                        <span style="font-weight: 600">Product Type:</span>
                        <span v-if="item.type && item.type.type">
                        {{ item.type.type }}
                        </span>
                        <span v-else>
                        N/A
                        </span>
                      </p>
                    </b-col>
                    <b-col sm="6" lg="4">
                      <p>
                        <span style="font-weight: 600">Product Stock:</span>
                        {{ item.stock }}
                      </p>
                    </b-col>
                    <div style='display: flex; flex-wrap: wrap; justify-content: left; padding-bottom: 15px; '>
                      <b-col v-for="(image, index) in item.variant_image" :key="'media_path' + index" style="display: flex; width: auto;">
                        <el-image
                          style="max-width: 150px; border-radius: 5px; aspect-ratio: 1/1; padding: 0"
                          fit="cover"
                          :src="`${uploadUrl}${image.media_path}`"
                          :preview-src-list="item.variant_image.map((x) => uploadUrl + x.media_path)"
                          :alt="image.alt"
                        >
                        </el-image>
                      </b-col>
                    </div>
                    <div style="margin-bottom:15px; padding: 0px 10px 0px 10px; border-bottom: 1px solid gray; width: 100%; opacity: 0.2;" />
                  </b-row>
                </div>
               
                <!-- Product highlight section -->
                <div v-if="activeCard === 'highlight'">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Highlight</h4>
                  </div>
                  <hr />
                  <div v-html="productData.highlight"></div>
                </div>
                
                <!-- Product Description section -->
                <div v-if="activeCard === 'description'">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Description</h4>
                  </div>
                  <hr />
                  <div v-html="productData.description"></div>
                </div>

                <!-- Product Shipping section -->
                <div v-if="activeCard === 'shipping'">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Shipping</h4>
                  </div>
                  <hr />
                  <div v-html="productData.shipping"></div>
                </div>
               
                <!-- Product images -->
                <div v-if="activeCard === 'images'">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Images</h4>
                  </div>
                  <hr />
                  <div style='display: flex; flex-wrap: wrap; justify-content: left; padding-bottom: 15px; gap: 10px;'>
                      <b-col v-for="(image, index) in productData.product_image" :key="'media_path' + index" style="display: flex; padding: 0; min-width:100px; max-width: 120px;">
                        <el-image
                          style="max-width: 150px; border-radius: 5px; aspect-ratio: 1/1; padding: 0"
                          fit="cover"
                          :src="`${uploadUrl}${image.media_path}`"
                          :preview-src-list="productData.product_image.map((x) => uploadUrl + x.media_path)"
                          :alt="image.alt"
                        >
                        </el-image>
                      </b-col>
                    </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 ml-0 color4 font-weight-bolder">Product Rating and Reviews</h4>
            </div>
            <hr />
          </b-card>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col md="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 ml-0 color4 font-weight-bolder">Highlight</h4>
            </div>
            <hr />
            <div v-html="productData.highlight"></div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 ml-0 color4 font-weight-bolder">Description</h4>
            </div>
            <hr />
            <div v-html="productData.description"></div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="mb-0 ml-0 color4 font-weight-bolder">Shipping</h4>
            </div>
            <hr />
            <div v-html="productData.shipping"></div>
          </b-card>
        </b-col>
      </b-row> -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormGroup,
  BFormInput,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import productStoreModule from "../productStoreModule";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BFormInput,
    BCollapse,
    draggable,
    vSelect,
  },
  data() {
    return {
      activeCard: 'variants',
      ability,
      // selectBankAc: "",
      selectedBankAc: "",
      page: "",
      collapse: "",
      years: [2022, 2021, 2020],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      formationDocuments: [],
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  methods: {
    updateActiveCard(name) {
      this.activeCard = name;
    },
    updateSortOrder(doc) {
      let data = { data: doc };
      console.log(data);
      this.$store
        .dispatch("app-product/updateSort", data)
        .then((response) => {
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.selectedBankAc = "";
          this.refetchData();
        })
        .catch((error) => {
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    hide() {
      this.$router.replace("/product/list");
    },
    handleDownload(item) {
      //this.$store.dispatch(this.DOCUMENT_APP_STORE_MODULE_NAME+'/fetchDocument', {id})
      axiosIns
        .get("/document", { params: { id: item.id }, responseType: "blob" })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    deleteProduct(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the product record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-product/deleteProduct", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectedBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteBank(id) {
      if (this.productData.banks.length <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one bank record."
        );
        return;
      }

      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the custodian bank record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-product/deleteBank", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.selectedBankAc = "";
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    triggerSelectBank(bank) {
      this.selectedBankAc = bank;
      this.setDocumentFormation(this.category_id);
      if (window.innerWidth <= 768) {
        setTimeout(function () {
          const y =
            document
              .getElementById("downloadBankWrapper")
              .getBoundingProductRect().top +
            window.pageYOffset -
            80;
          window.scrollTo({ top: y, behavior: "smooth" });
        }, 100);
      }
    },

    setDocumentFormation(id) {
      console.log("id", id);
      let original_year = "",
        count = 0;
      this.formationDocuments = [];

      const newArray = this.selectedBankAc.documents.filter(
        (item) => id == item.document_category_id
      );
      newArray.forEach((item, index) => {
        let year = item.file_date.slice(0, 4);
        if (original_year != year) {
          this.formationDocuments.push({ year: year, data: [] });
          original_year = year;
          if (index > 0) {
            count++;
          }
        }

        this.formationDocuments[count].data.push(item);
      });
    },
  },
  setup() {
    const productData = ref({});
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);

    const CLIENT_APP_STORE_MODULE_NAME = "app-product";

    const product = {
      id: 0,
      account_number: "",
      name_cn: "",
      name_en: "",

      account_type_id: [],
      is_company: 0,
      banks: [{ id: 0, bank_id: null, account_number: "" }],
      controlling_persons: [
        {
          name: "",
          email: "",
          identity_document: "",
          date_of_birth: null,
          person_type_id: null,
        },
      ],
    };

    const demoProduct = {
      id: 0,
      name_cn: "PAPER ONE Ltd",
      name_en: "PAPER ONE Ltd",
      account_type_id: [],
      is_company: 0,
      account_status_id: parseInt(1),
      address: "Flat A, 10/F, Tsuen King Wai Building, Tsuen Wan, KLN",
      open_date: "2022-10-06",
      authorized_persons: [2, 5],
      date_of_birth: "1980-11-25",
      pi_expiration_date: "2023-10-06",
      contact_number: "26612345",
      relationship_manager_id: 2,
      remarks: "SI",
      email: "jebb.wong@jvsakk.com",
      hkid: "Z003352(A)",
      account_types: [1, 2, 3, 4, 5, 6, 7],
      banks: [{ id: 0, bank_id: 3, account_number: "0127121009668" }],
      controlling_persons: [
        {
          name: "Mary Chan",
          email: "jebb.wong@jvsakk.com",
          identity_document: "Z003352(A)",
          date_of_birth: "1980-11-25",
          person_type_id: 1,
        },
      ],
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-product/fetchProduct", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          productData.value = response.data.product;

          console.log('product data ', productData)
        })
        .catch((error) => {
          console.log("error when fetching product", error);
          if (error.response.status === 404) {
            productData.value = undefined;
          }
        });
    };

    const documentCategoryOptions = ref([]);
    const category_id = ref(null);

    documentCategoryOptions.value = [
      {
        id: 3,
        category_name: "Bank Document",
      },
    ];

    category_id.value = 3;

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { document_category: true })
        .then((response) => {
          // documentCategoryOptions.value = response.data.document_categories;
          // category_id.value = response.data.document_categories[0].id;
        });
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    return {
      productData,
      refetchData,
      documentCategoryOptions,
      category_id,
      uploadUrl,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.info-wrap {
  .col-12 {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
  }

  .tag-list {
    a {
      margin-right: 5px;
    }
  }
}

.product-img {
  width: auto;
  height: 100%;
  aspect-ratio: 1/1;
  max-height: 410px;
  object-fit: cover !important;
  border-radius: 5px;
}
</style>

<style lang="scss" scoped>
.drop-btn {
  margin-top: 5px;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  background-color: rgba(148, 200, 187, 0.2);

  svg {
    transition: all 300ms ease;
    top: 2px;
    right: 0px;
  }

  &.not-collapsed {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.drop-item {
  display: block;
  margin-top: 5px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid rgba(189, 189, 189, 0.5);
}
</style>
